<div class="bg-light-grey upload-documents p-sm">
	<div class="content-container small mv-md">
		<p class="color-primary title-f">{{ "uploadDocs.title" | translate }}</p>
		<p>{{ "uploadDocs.desc" | translate }}</p>

		<mat-card>
			<div fxLayout="row" fxLayoutAlign="space-between">
				<div>
					<div fxFlex>
						<img src="assets/icons/id-icon.png" alt="id" class="desktop-icon" />
					</div>
					<div style="max-width: 300px">
						<div class="title-container">
							<div class="title">Photos of MyKad or Passport</div>
							<div class="desc">
								Include the front and back images of owner's Mykad or the ID
								page of owner's passport.
							</div>
						</div>
					</div>
				</div>

				<div style="text-align: right">
					<input
						type="file"
						accept="image/*, application/pdf"
						multiple
						id="ID"
						name="ID"
						(change)="handleFileInput($event)"
						style="display: none"
					/>
					<!-- <div
            class="desc color-blue cursor-pointer"
            (click)="showSampleDialog('VehicleGrant')"
          >
            View sample here
          </div> -->
					<button
						mat-stroked-button
						class="mt-sm"
						*ngIf="errorActivatedPolicy === false"
					>
						<label
							for="ID"
							style="
								display: flex;
								justify-content: center;
								align-items: center;
							"
						>
							<mat-icon class="">add_circle</mat-icon
							>{{ "common.upload" | translate }}
						</label>
					</button>
				</div>
			</div>
			<div *ngIf="fileToShow?.ID.length" class="filename">
				<mat-divider></mat-divider>
				<div class="pt-sm">
					<div *ngFor="let item of fileToShow?.ID">
						{{ item.file.name }}
						<button
							mat-icon-button
							(click)="deleteFile('ID', item.file)"
							*ngIf="errorActivatedPolicy === false"
						>
							<mat-icon>cancel</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</mat-card>

		<mat-card>
			<div fxLayout="row" fxLayoutAlign="space-between">
				<div>
					<div fxFlex>
						<img
							src="assets/icons/id-icon.png"
							alt="lou"
							class="desktop-icon"
						/>
					</div>
					<div style="max-width: 300px">
						<div class="title-container">
							<div class="title">
								Copy of letter of Undertaking (LOU) / Vehicle Sales Order (VSO)
							</div>
							<div class="desc">
								Include bank or dealer letterhead in the document.
							</div>
						</div>
					</div>
				</div>

				<div style="text-align: right">
					<input
						type="file"
						accept="application/pdf"
						multiple
						id="LOU"
						name="LOU"
						(change)="handleFileInput($event)"
						style="display: none"
					/>
					<!-- <div
            class="desc color-blue cursor-pointer"
            (click)="showSampleDialog('LOU')"
          >
            View sample here
          </div> -->
					<button
						mat-stroked-button
						class="mt-sm"
						*ngIf="errorActivatedPolicy === false"
					>
						<label
							for="LOU"
							style="
								display: flex;
								justify-content: center;
								align-items: center;
							"
						>
							<mat-icon class="">add_circle</mat-icon
							>{{ "common.upload" | translate }}
						</label>
					</button>
				</div>
			</div>
			<div class="filename" *ngIf="fileToShow?.LOU.length">
				<mat-divider></mat-divider>
				<div class="pt-sm">
					<div *ngFor="let item of fileToShow?.LOU">
						{{ item.file.name }}
						<button
							mat-icon-button
							(click)="deleteFile('LOU', item.file)"
							*ngIf="errorActivatedPolicy === false"
						>
							<mat-icon>cancel</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</mat-card>

		<mat-card>
			<div fxLayout="row" fxLayoutAlign="space-between">
				<div>
					<div fxFlex>
						<img
							src="assets/image/vehicle-grant.png"
							alt="grant"
							class="desktop-icon"
						/>
					</div>
					<div>
						<div class="title-container">
							<div class="title">
								{{ "uploadDocs.vehicleGrant" | translate }}
							</div>
							<div class="desc">
								{{ "uploadDocs.vehicleGrantDesc" | translate }}
							</div>
						</div>
					</div>
				</div>

				<div style="text-align: right">
					<input
						type="file"
						accept="image/*, application/pdf"
						id="VehicleGrant"
						name="VehicleGrant"
						(change)="handleFileInput($event)"
						style="display: none"
					/>
					<div
						class="desc color-blue cursor-pointer"
						(click)="showSampleDialog('VehicleGrant')"
					>
						View sample here
					</div>
					<button
						mat-stroked-button
						class="mt-sm"
						*ngIf="errorActivatedPolicy === false"
					>
						<label
							for="VehicleGrant"
							style="
								display: flex;
								justify-content: center;
								align-items: center;
							"
						>
							<mat-icon class="">add_circle</mat-icon
							>{{ "common.upload" | translate }}
						</label>
					</button>
				</div>
			</div>
			<div class="filename" *ngIf="fileToShow?.VehicleGrant.length">
				<mat-divider></mat-divider>
				<div class="pt-sm">
					<div *ngFor="let item of fileToShow?.VehicleGrant">
						{{ item.file.name }}
						<button
							mat-icon-button
							(click)="deleteFile('VehicleGrant', item.file)"
							*ngIf="errorActivatedPolicy === false"
						>
							<mat-icon>cancel</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</mat-card>

		<mat-card>
			<div fxLayout="row">
				<div>
					<img
						src="assets/image/car-icon.png"
						alt="car-icon"
						class="desktop-icon"
					/>
				</div>
				<div>
					<div fxLayoutAlign="space-between">
						<div class="title-container">
							<div class="title">
								{{ "uploadDocs.vehiclePhoto" | translate }}
							</div>
							<div class="desc">
								{{ "uploadDocs.vehiclePhotoDesc" | translate }}
							</div>
						</div>
						<div
							class="desc color-blue cursor-pointer"
							style="text-align: right"
							(click)="showSampleDialog('VehiclePhoto')"
						>
							View sample here
						</div>
					</div>

					<mat-card>
						<input
							type="file"
							accept="image/*, application/pdf"
							id="FrontCar"
							name="FrontCar"
							(change)="handleFileInput($event)"
							style="display: none"
						/>
						<div fxLayout="row" fxLayoutAlign="space-between">
							<p class="mdDesc-f bold">{{ "uploadDocs.fcar" | translate }}</p>
							<div>
								<button
									mat-stroked-button
									*ngIf="errorActivatedPolicy === false"
								>
									<label
										for="FrontCar"
										style="
											display: flex;
											justify-content: center;
											align-items: center;
										"
									>
										<mat-icon class="color-dark-blue">add_circle</mat-icon
										>{{ "common.upload" | translate }}
									</label>
								</button>
							</div>
						</div>
						<div
							class="filename vehicle-photo"
							*ngIf="fileToShow?.FrontCar.length"
						>
							<div *ngFor="let item of fileToShow?.FrontCar">
								{{ item.file.name }}
								<button
									mat-icon-button
									(click)="deleteFile('FrontCar', item.file)"
									*ngIf="errorActivatedPolicy === false"
								>
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>
					</mat-card>
					<mat-card>
						<input
							type="file"
							accept="image/*, application/pdf"
							id="BackCar"
							name="BackCar"
							(change)="handleFileInput($event)"
							style="display: none"
						/>
						<div fxLayout="row" fxLayoutAlign="space-between">
							<p class="mdDesc-f bold">{{ "uploadDocs.bcar" | translate }}</p>

							<div>
								<button
									mat-stroked-button
									*ngIf="errorActivatedPolicy === false"
								>
									<label
										for="BackCar"
										style="
											display: flex;
											justify-content: center;
											align-items: center;
										"
									>
										<mat-icon class="">add_circle</mat-icon
										>{{ "common.upload" | translate }}
									</label>
								</button>
							</div>
						</div>
						<div
							class="filename vehicle-photo"
							*ngIf="fileToShow?.BackCar.length"
						>
							<div *ngFor="let item of fileToShow?.BackCar">
								{{ item.file.name }}
								<button
									mat-icon-button
									(click)="deleteFile('BackCar', item.file)"
									*ngIf="errorActivatedPolicy === false"
								>
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>
					</mat-card>
					<mat-card>
						<input
							type="file"
							accept="image/*, application/pdf"
							id="LeftCar"
							name="LeftCar"
							(change)="handleFileInput($event)"
							style="display: none"
						/>
						<div fxLayout="row" fxLayoutAlign="space-between">
							<p class="mdDesc-f bold">
								{{ "uploadDocs.lside" | translate }}
							</p>
							<div>
								<button
									mat-stroked-button
									*ngIf="errorActivatedPolicy === false"
								>
									<label
										for="LeftCar"
										style="
											display: flex;
											justify-content: center;
											align-items: center;
										"
									>
										<mat-icon class="">add_circle</mat-icon
										>{{ "common.upload" | translate }}
									</label>
								</button>
							</div>
						</div>
						<div
							class="filename vehicle-photo"
							*ngIf="fileToShow?.LeftCar.length"
						>
							<div *ngFor="let item of fileToShow?.LeftCar">
								{{ item.file.name }}
								<button
									mat-icon-button
									(click)="deleteFile('LeftCar', item.file)"
									*ngIf="errorActivatedPolicy === false"
								>
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>
					</mat-card>
					<mat-card>
						<input
							type="file"
							accept="image/*, application/pdf"
							id="RightCar"
							name="RightCar"
							(change)="handleFileInput($event)"
							style="display: none"
						/>
						<div fxLayout="row" fxLayoutAlign="space-between">
							<p class="mdDesc-f bold">
								{{ "uploadDocs.rside" | translate }}
							</p>
							<div>
								<button
									mat-stroked-button
									*ngIf="errorActivatedPolicy === false"
								>
									<label
										for="RightCar"
										style="
											display: flex;
											justify-content: center;
											align-items: center;
										"
									>
										<mat-icon class="">add_circle</mat-icon
										>{{ "common.upload" | translate }}
									</label>
								</button>
							</div>
						</div>
						<div
							class="filename vehicle-photo"
							*ngIf="fileToShow?.RightCar.length"
						>
							<div *ngFor="let item of fileToShow?.RightCar">
								{{ item.file.name }}
								<button
									mat-icon-button
									(click)="deleteFile('RightCar', item.file)"
									*ngIf="errorActivatedPolicy === false"
								>
									<mat-icon>cancel</mat-icon>
								</button>
							</div>
						</div>
					</mat-card>
				</div>
			</div>
		</mat-card>

		<button
			mat-flat-button
			class="secondary-btn mt-md"
			(click)="handleSubmit()"
			[disabled]="fileToUpload.length === 0"
		>
			{{ "common.submit" | translate }}
		</button>
	</div>
</div>
